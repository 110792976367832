import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line
import ReactPlayer from "react-player";
import myVideo from "images/racerecap.mp4";
// import img from "images/plaickner-06670-min2.jpg";
import img from "images/plaickner-06924.jpg";
import imgMobile from "images/plaickner-06924.jpg";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
// import img from "images/josef/plaickner-07752.jpg";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500 `}
  }
`;
const Container = isMobile
  ? styled.div`
      ${tw`relative -mx-8 -mt-8 bg-center  bg-cover`}
      background-image: url("${imgMobile}");
    `
  : styled.div`
      ${tw`relative -mx-8 -mt-8 bg-center  bg-cover`}
      background-image: url("${img}");
    `;

const OpacityOverlay = isMobile
  ? tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`
  : tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Video = () => {
  return (
    <ReactPlayer
      playing
      muted
      loop
      url={myVideo}
      height="auto"
      width="auto"
      controls={false}
      playIcon="false"
      playsinline="false"
    />
  );
};

export default () => {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const navigate = useNavigate();

  const navLinks = [
    <NavLinks key={1}>
      <NavLink>
        <button onClick={() => scrollTo("scroll-about")}>About</button>
      </NavLink>
      <NavLink>
        <button onClick={() => scrollTo("scroll-download")}>Download</button>
      </NavLink>
      <NavLink>
        <button onClick={() => scrollTo("scroll-pricing")}>Pricing</button>
      </NavLink>
      <NavLink>
        <button onClick={() => scrollTo("scroll-tutorial")}>Tutorial</button>
      </NavLink>
      <NavLink>
        <button onClick={() => scrollTo("scroll-contact")}>Contact</button>
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink>
        <button onClick={() => navigate("/profile")}>Profile</button>
      </PrimaryLink>
    </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            {/* <Notification>We have now launched operations in Europe.</Notification> */}
            <Heading>
              <span>The entire race</span>
              <br />
              <SlantedBackground>in one app.</SlantedBackground>
            </Heading>
          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url="{myVideo}"
              background="transparent"
            /> */}
            <Video />
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
