import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { auth } from "../../firebase.js";
import { Container } from "@mui/material";

export default function FormDialog({
  amount = 0,
  isOpen = false,
  license = "",
  closeUpgrade,
  onStandard,
  onPremium,
}) {
  var text = "";

  const handleClickOpen = () => {
    closeUpgrade();
  };

  const handleClose = () => {
    closeUpgrade();
  };

  const sendMail = () => {
    const email = auth.currentUser.email;

    const Http = new XMLHttpRequest();

    const url =
      "https://europe-west3-mouserstudios-website.cloudfunctions.net/sendMail?name=" +
      "licensekey= " +
      license +
      "&email=" +
      email +
      "&message=" +
      text +
      "&subject=Biathlon Website Upgrade License";
    Http.open("GET", url);
    Http.send();
    Http.onreadystatechange = (e) => {
      if (Http.readyState == 4) {
        if (Http.status == 200) {
          alert("Message sent! We will contact you soon.");
        } else {
          alert("Error sending message");
        }
      }
    };
  };

  const [value, setValue] = React.useState("standard");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (amount == 1) {
    return (
      <div>
        <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle>Upgrade</DialogTitle>
          <DialogContent>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="standard"
                  control={<Radio />}
                  label="Standard"
                />
                <FormControlLabel
                  value="premium"
                  control={<Radio />}
                  label="Premium"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            <div>
              {value == "standard" || value == "premium" ? (
                <DialogContentText>
                  You want upgrade your license key "{license}" to {value}?
                </DialogContentText>
              ) : (
                <DialogContentText>
                  You want upgrade your license key "{license}"? Please enter
                  your change request below. We will contact you.
                </DialogContentText>
              )}
            </div>

            <div>
              {value == "standard" || value == "premium" ? (
                <div></div>
              ) : (
                <TextField
                  onChange={(e) => {
                    text = e.target.value;
                  }}
                  style={{
                    color: "#1e315b",
                    borderColor: "#1e315b",
                  }}
                  autoFocus
                  margin="dense"
                  id="change"
                  label="Change request"
                  fullWidth
                  multiline={true}
                  minRows={4}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{
                color: "#1e315b",
                borderColor: "#1e315b",
              }}
            >
              Cancel
            </Button>

            <div>
              {value == "standard" || value == "premium" ? (
                <Button
                  onClick={() => {
                    if (value == "standard") {
                      onStandard(license);
                    } else {
                      onPremium(license);
                    }
                    handleClose();
                  }}
                  style={{
                    color: "#1e315b",
                    borderColor: "#1e315b",
                  }}
                >
                  next
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    sendMail();
                    handleClose();
                  }}
                  style={{
                    color: "#1e315b",
                    borderColor: "#1e315b",
                  }}
                >
                  Send request
                </Button>
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Upgrade</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You want upgrade your license key "{license}"? Please enter your
            change request below. We will contact you.
          </DialogContentText>
          <TextField
            onChange={(e) => {
              text = e.target.value;
            }}
            style={{
              color: "#1e315b",
              borderColor: "#1e315b",
            }}
            autoFocus
            margin="dense"
            id="change"
            label="Change request"
            fullWidth
            multiline={true}
            minRows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              color: "#1e315b",
              borderColor: "#1e315b",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              sendMail();
              handleClose();
            }}
            style={{
              color: "#1e315b",
              borderColor: "#1e315b",
            }}
          >
            Send request
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
