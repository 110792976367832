import React from "react";
import { useRef } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Training from "components/features/TwoColWithButton.js";

import Hero from "components/hero/BackgroundAsImage.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import Features from "components/features/ThreeColSimple.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import shootingImage from "images/shooting2.jpg";
import timingImage from "images/timing4.jpg";
import performance from "images/performance3.jpg";
import Download from "components/cta/DownloadApp.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher";
import ContactUs from "components/forms/SimpleContactUs.js";
import trainingImg from "images/training.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Hero />
      <MainFeature />
      <Features />
      <MainFeature1
        altDesc="Live biathlon tracking with detailed shooting results and time measurements"
        subheading={<Subheading>Sooting</Subheading>}
        heading="Never miss a shot."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        description={
          "Keep every shot in sight, either at the shooting range or on the track. Record the shot pattern and the wind to keep your team up to date. Easy to inform athletes about their shooting performance to achieve better results."
        }
        imageSrc={shootingImage}
      />
      <MainFeature1
        altDesc="Biathlon app interface displaying shooting scores and race times"
        subheading={<Subheading>Timing</Subheading>}
        heading="From start to finish, we've got you covered."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        description="Create a start list and record your athletes' times with ease and stay ahead of the race. Our app also allows you to share all the information with your team members, making it easier for you to work together towards your goals. Don't miss a split with our live viewer mode. You can easily keep track of every split time and stay updated on all the critical moments of the race. "
        imageSrc={timingImage}
        textOnLeft={false}
      />
      <MainFeature1
        altDesc="Comprehensive biathlon results with shooting accuracy and lap times highlighted"
        subheading={<Subheading>Analyze Data</Subheading>}
        heading="Take control of your athletes shooting and race performance."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        description={
          "Track and analyze your hit rates in the prone and standing positions. You can view all race performances in detail and compare your current performance with those of previous seasons."
        }
        imageSrc={performance}
      />
      <Training
        altDesc="Performance analysis in biathlon, showing shooting results and timing stats on the app"
        subheading={<Subheading>Traings mode</Subheading>}
        imageSrc={trainingImg}
        imageBorder={false}
        imageDecoratorBlob={false}
        textOnLeft={false}
        heading={"Precision and Speed in Focus!"}
        description={
          "Our intuitive interface and advanced timing features make tracking your shooting and running times a breeze. Train like never before, hit your targets, and conquer the course."
        }
      />
      <Download />
      <Pricing />
      <FAQ
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="Tutorial"
        heading={
          <>
            Do you need <span tw="text-primary-500">help ?</span>
          </>
        }
      />
      <ContactUs />
      {/* <Blog /> */}
      {/* <ContactUsForm /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
