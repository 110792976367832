import React, { useState, useEffect } from "react";
import { auth } from "../firebase.js";
import { useNavigate } from "react-router-dom";
import Login from "./Login.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Dashboard from "./dashboard.js";
import { getAuth } from "firebase/auth";

const redirectToLogin = (navigate) => {
  navigate("/login", { replace: true });
};

export default () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoading(false);
        setIsUserLoggedIn(true);
      } else {
        setIsLoading(false);
        setIsUserLoggedIn(false);
        redirectToLogin(navigate);
      }
    });
  }, []);

  if (isLoading) {
    return <div></div>;
  } else {
    return <Dashboard />;
  }
};
