import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              <strong>Last updated July 06, 2023</strong>
            </p>
            <p>
              This privacy notice for Ennemoser Lukas ("<strong>Company</strong>
              ", "<strong>we</strong>," "<strong>us</strong>," or "
              <strong>our</strong>"), describes how and why we might collect,
              store, use, and/or share ("<strong>process</strong>") your
              information when you use our services ("<strong>Services</strong>
              "), such as when you:
            </p>
            <ul>
              <li>
                <p>
                  Visit our website at https://biathlon.mouserstudios.com, or
                  any website of ours that links to this privacy notice
                </p>
                <p>
                  Download and use our mobile application (Race Recap), or any
                  other application of ours that links to this privacy notice
                </p>
                <p>
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </p>
              </li>
            </ul>
            <p>
              <strong>Questions or concerns?</strong> Reading this privacy
              notice will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services. If you still have any questions or concerns,
              please contact us at mouserstudios@gmail.com.
            </p>
            <h1>SUMMARY OF KEY POINTS</h1>
            <p>
              <strong>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for.
              </strong>
            </p>
            <p>
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with Ennemoser Lukas and
              the Services, the choices you make, and the products and features
              you use. Learn more about personal information you disclose to us.
            </p>
            <p>
              <strong>Do we process any sensitive personal information?</strong>{" "}
              We do not process sensitive personal information.
            </p>
            <p>
              <strong>Do we receive any information from third parties?</strong>{" "}
              We do not receive any information from third parties.
            </p>
            <p>
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so. Learn more about how we
              process your information.
            </p>
            <p>
              <strong>
                In what situations and with which parties do we share personal
                information?
              </strong>{" "}
              We may share information in specific situations and with specific
              third parties. Learn more about when and with whom we share your
              personal information.
            </p>
            <p>
              <strong>How do we keep your information safe?</strong> We have
              organizational and technical processes and procedures in place to
              protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Learn more about how we keep your information safe.
            </p>
            <p>
              <strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information. Learn
              more about your privacy rights.
            </p>
            <p>
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by submitting a data subject access
              request, or by contacting us. We will consider and act upon any
              request in accordance with applicable data protection laws.
            </p>
            <p>
              Want to learn more about what Ennemoser Lukas does with any
              information we collect? Review the privacy notice in full.
            </p>
            <h2>TABLE OF CONTENTS</h2>
            <ul>
              <li>
                <p>1. WHAT INFORMATION DO WE COLLECT?</p>
              </li>
              <li>
                <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
              </li>
              <li>
                <p>
                  3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </p>
              </li>
              <li>
                <p>
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </p>
              </li>
              <li>
                <p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
              </li>
              <li>
                <p>6. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
              </li>
              <li>
                <p>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
              </li>
              <li>
                <p>8. DO WE COLLECT INFORMATION FROM MINORS?</p>
              </li>
              <li>
                <p>9. WHAT ARE YOUR PRIVACY RIGHTS?</p>
              </li>
              <li>
                <p>10. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
              </li>
              <li>
                <p>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
              </li>
              <li>
                <p>12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
              </li>
              <li>
                <p>13. DO WE MAKE UPDATES TO THIS NOTICE?</p>
              </li>
              <li>
                <p>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
              </li>
              <li>
                <p>
                  15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </p>
              </li>
            </ul>
            <h1>1. WHAT INFORMATION DO WE COLLECT?</h1>
            <h2>Personal information you disclose to us</h2>
            <p>
              <strong>In Short:</strong>We collect personal information that you
              provide to us.
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>
            <p>
              <strong>Personal Information Provided by You. </strong>The
              personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
            </p>
            <ul>
              <li>email addresses</li>
            </ul>
            <p>
              <strong>Sensitive Information. </strong>We do not process
              sensitive information.
            </p>
            <p>
              <strong>Application Data. </strong>If you use our application(s),
              we also may collect the following information if you choose to
              provide us with access or permission:
            </p>
            <ul>
              <li>
                Mobile Device Data. We automatically collect device information
                (such as your mobile device ID, model, and manufacturer),
                operating system, version information and system configuration
                information, device and application identification numbers,
                browser type and version, hardware model Internet service
                provider and/or mobile carrier, and Internet Protocol (IP)
                address (or proxy server). If you are using our application(s),
                we may also collect information about the phone network
                associated with your mobile device, your mobile device’s
                operating system or platform, the type of mobile device you use,
                your mobile device’s unique device ID, and information about the
                features of our application(s) you accessed.
              </li>
            </ul>
            <p>
              This information is primarily needed to maintain the security and
              operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
            </p>
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <h1>2. HOW DO WE PROCESS YOUR INFORMATION?</h1>
            <p>
              <strong>In Short: </strong>We process your information to provide,
              improve, and administer our Services, communicate with you, for
              security and fraud prevention, and to comply with law. We may also
              process your information for other purposes with your consent.
            </p>
            <p>
              <strong>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </strong>
            </p>
            <ul>
              <li>
                <p>
                  <strong>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.{" "}
                  </strong>
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    To save or protect an individual's vital interest.{" "}
                  </strong>
                  We may process your information when necessary to save or
                  protect an individual’s vital interest, such as to prevent
                  harm.
                </p>
              </li>
            </ul>
            <h1>
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </h1>
            <p>
              <strong>In Short: </strong>We only process your personal
              information when we believe it is necessary and we have a valid
              legal reason (i.e., legal basis) to do so under applicable law,
              like with your consent, to comply with laws, to provide you with
              services to enter into or fulfill our contractual obligations, to
              protect your rights, or to fulfill our legitimate business
              interests.
            </p>
            <p>
              <strong>
                <i>
                  If you are located in the EU or UK, this section applies to
                  you.
                </i>
              </strong>
            </p>
            <p>
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Consent. </strong>We may process your information if
                  you have given us permission (i.e., consent) to use your
                  personal information for a specific purpose. You can withdraw
                  your consent at any time. Learn more about withdrawing your
                  consent.
                </p>
              </li>
              <li>
                <p>
                  <strong>Legal Obligations. </strong>We may process your
                  information where we believe it is necessary for compliance
                  with our legal obligations, such as to cooperate with a law
                  enforcement body or regulatory agency, exercise or defend our
                  legal rights, or disclose your information as evidence in
                  litigation in which we are involved.
                </p>
              </li>
              <li>
                <p>
                  <strong>Vital Interests. </strong>We may process your
                  information where we believe it is necessary to protect your
                  vital interests or the vital interests of a third party, such
                  as situations involving potential threats to the safety of any
                  person.
                </p>
              </li>
            </ul>
            <p>
              <strong>
                <i>
                  If you are located in Canada, this section applies to you.
                </i>
              </strong>
            </p>
            <p>
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time.
            </p>
            <p>
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </p>
            <ul>
              <li>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </li>
              <li>For investigations and fraud detection and prevention</li>
              <li>
                For business transactions provided certain conditions are met
              </li>
              <li>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </li>
              <li>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </li>
              <li>
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
              </li>
              <li>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </li>
              <li>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </li>
              <li>
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </li>
              <li>
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </li>
              <li>
                If the information is publicly available and is specified by the
                regulations
              </li>
            </ul>
            <h1>
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h1>
            <p>
              <strong>In Short: </strong>We may share information in specific
              situations described in this section and/or with the following
              third parties.
            </p>
            <p>
              We may need to share your personal information in the following
              situations:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Business Transfers. </strong>We may share or transfer
                  your information in connection with, or during negotiations
                  of, any merger, sale of company assets, financing, or
                  acquisition of all or a portion of our business to another
                  company.
                </p>
              </li>
            </ul>
            <h1>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h1>
            <p>
              <strong>In Short: </strong>We may use cookies and other tracking
              technologies to collect and store your information.
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
            <h1>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h1>
            <p>
              <strong>In Short: </strong>We keep your information for as long as
              necessary to fulfill the purposes outlined in this privacy notice
              unless otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
            <h1>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h1>
            <p>
              <strong>In Short: </strong>We aim to protect your personal
              information through a system of organizational and technical
              security measures.
            </p>
            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
            <h1>8. DO WE COLLECT INFORMATION FROM MINORS?</h1>
            <p>
              <strong>In Short: </strong>We do not knowingly collect data from
              or market to children under 18 years of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at
              mouserstudios@gmail.com.
            </p>
            <h1>9. WHAT ARE YOUR PRIVACY RIGHTS?</h1>
            <p>
              <strong>In Short: </strong>In some regions, such as the European
              Economic Area (EEA), United Kingdom (UK), and Canada, you have
              rights that allow you greater access to and control over your
              personal information. You may review, change, or terminate your
              account at any time.
            </p>
            <p>
              In some regions (like the EEA, UK, and Canada), you have certain
              rights under applicable data protection laws. These may include
              the right (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. You can make such a request by contacting us by using
              the contact details provided in the section "HOW CAN YOU CONTACT
              US ABOUT THIS NOTICE?" below.
            </p>
            <p>
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>
            <p>
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your Member State data protection authority
              or UK data protection authority.
            </p>
            <p>
              If you are located in Switzerland, you may contact the Federal
              Data Protection and Information Commissioner.
            </p>
            <p>
              Withdrawing your consent: If we are relying on your consent to
              process your personal information, which may be express and/or
              implied consent depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent at any time by contacting us by using the contact details
              provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?" below.
            </p>
            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <h2>Account Information</h2>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li>
                Log in to your account settings and update your user account.
              </li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>
            <p>
              <strong>Cookies and similar technologies: </strong>Most Web
              browsers are set to accept cookies by default. If you prefer, you
              can usually choose to set your browser to remove cookies and to
              reject cookies. If you choose to remove cookies or reject cookies,
              this could affect certain features or services of our Services.
              You may also opt out of interest-based advertising by advertisers
              on our Services.
            </p>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at mouserstudios@gmail.com.
            </p>
            <h1>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h1>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
            <h1>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h1>
            <p>
              <strong>In Short: </strong>Yes, if you are a resident of
              California, you are granted specific rights regarding access to
              your personal information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.,
              backups, etc.).
            </p>
            <h2>CCPA Privacy Notice</h2>
            <p>The California Code of Regulations defines a "resident" as:</p>
            <p>
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </p>
            <p>
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </p>
            <p>All other individuals are defined as "non-residents."</p>
            <p>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            <h3>What categories of personal information do we collect?</h3>
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <table>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
              <tr>
                <td>A. Identifiers</td>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  B. Personal information categories listed in the California
                  Customer Records statute
                </td>
                <td>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or federal law
                </td>
                <td>Gender and date of birth</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E. Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>
                <td>
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>J. Education Information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from other personal information</td>
                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>L. Sensitive Personal Information</td>
                <td></td>
                <td>NO</td>
              </tr>
            </table>
            <p>
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </p>
            <ul>
              <li>
                <p>Receiving help through our customer support channels;</p>
              </li>
              <li>
                <p>Participation in customer surveys or contests; and</p>
              </li>
              <li>
                <p>
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.
                </p>
              </li>
            </ul>
            <h3>How do we use and share your personal information?</h3>
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </p>
            <p>
              You may contact us by email at mouserstudios@gmail.com, or by
              referring to the contact details at the bottom of this document.
            </p>
            <p>
              If you are using an authorized agent to exercise your right to opt
              out we may deny a request if the authorized agent does not submit
              proof that they have been validly authorized to act on your
              behalf.
            </p>
            <h3>Will your information be shared with anyone else?</h3>
            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf, following the same
              strict privacy protection obligations mandated by the CCPA.
            </p>
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal information.
            </p>
            <p>
              Ennemoser Lukas has not disclosed, sold, or shared any personal
              information to third parties for a business or commercial purpose
              in the preceding twelve (12) months. Ennemoser Lukas will not sell
              or share personal information in the future belonging to website
              visitors, users, and other consumers.
            </p>
            <h3>Your rights with respect to your personal data</h3>
            <p>
              <u>Right to request deletion of the data — Request to delete</u>
            </p>
            <p>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </p>
            <p>
              <u>Right to be informed — Request to know</u>
            </p>
            <p>Depending on the circumstances, you have a right to know:</p>
            <ul>
              <li>
                <p>whether we collect and use your personal information;</p>
              </li>
              <li>
                <p>the categories of personal information that we collect;</p>
              </li>
              <li>
                <p>
                  the purposes for which the collected personal information is
                  used;
                </p>
              </li>
              <li>
                <p>
                  whether we sell your personal information to third parties;
                </p>
              </li>
              <li>
                <p>
                  the categories of personal information that we sold, shared,
                  or disclosed for a business purpose;
                </p>
              </li>
              <li>
                <p>
                  the categories of third parties to whom the personal
                  information was sold, shared, or disclosed for a business
                  purpose;
                </p>
              </li>
              <li>
                <p>
                  the business or commercial purpose for collecting, selling, or
                  sharing personal information; and
                </p>
              </li>
              <li>
                <p>
                  the specific pieces of personal information we collected about
                  you.
                </p>
              </li>
            </ul>
            <p>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </p>
            <p>
              <u>
                Right to Non-Discrimination for the Exercise of a Consumer’s
                Privacy Rights
              </u>
            </p>
            <p>
              We will not discriminate against you if you exercise your privacy
              rights.
            </p>
            <p>
              <u>
                Right to Limit Use and Disclosure of Sensitive Personal
                Information
              </u>
            </p>
            <p>We do not process consumer's sensitive personal information.</p>
            <p>
              <u>Verification process</u>
            </p>
            <p>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g., phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>
            <p>
              <u>Other privacy rights</u>
            </p>
            <ul>
              <li>
                <p>
                  You may object to the processing of your personal information.
                </p>
              </li>
              <li>
                <p>
                  You may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the information.
                </p>
              </li>
              <li>
                <p>
                  You can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                </p>
              </li>
              <li>
                <p>
                  You may request to opt out from future selling or sharing of
                  your personal information to third parties. Upon receiving an
                  opt-out request, we will act upon the request as soon as
                  feasibly possible, but no later than fifteen (15) days from
                  the date of the request submission.
                </p>
              </li>
            </ul>
            <p>
              To exercise these rights, you can contact us by email at
              mouserstudios@gmail.com, or by referring to the contact details at
              the bottom of this document. If you have a complaint about how we
              handle your data, we would like to hear from you.
            </p>
            <h1>12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h1>
            <p>
              <strong>In Short: </strong>Yes, if you are a resident of Virginia,
              you may be granted specific rights regarding access to and use of
              your personal information.
            </p>
            <h2>Virginia CDPA Privacy Notice</h2>
            <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
            <p>
              "Consumer" means a natural person who is a resident of the
              Commonwealth acting only in an individual or household context. It
              does not include a natural person acting in a commercial or
              employment context.
            </p>
            <p>
              "Personal data" means any information that is linked or reasonably
              linkable to an identified or identifiable natural person.
              "Personal data" does not include de-identified data or publicly
              available information.
            </p>
            <p>
              "Sale of personal data" means the exchange of personal data for
              monetary consideration.
            </p>
            <p>
              If this definition "consumer" applies to you, we must adhere to
              certain rights and obligations regarding your personal data.
            </p>
            <p>
              The information we collect, use, and disclose about you will vary
              depending on how you interact with Ennemoser Lukas and our
              Services. To find out more, please visit the following links:
            </p>
            <ul>
              <li>
                <p>Personal data we collect</p>
              </li>
              <li>
                <p>How we use your personal data</p>
              </li>
              <li>
                <p>When and with whom we share your personal data</p>
              </li>
            </ul>
            <p>
              <u>Your rights with respect to your personal data</u>
            </p>
            <ul>
              <li>
                <p>
                  Right to be informed whether or not we are processing your
                  personal data
                </p>
              </li>
              <li>
                <p>Right to access your personal data</p>
              </li>
              <li>
                <p>Right to correct inaccuracies in your personal data</p>
              </li>
              <li>
                <p>Right to request deletion of your personal data</p>
              </li>
              <li>
                <p>
                  Right to obtain a copy of the personal data you previously
                  shared with us
                </p>
              </li>
              <li>
                <p>
                  Right to opt out of the processing of your personal data if it
                  is used for targeted advertising, the sale of personal data,
                  or profiling in furtherance of decisions that produce legal or
                  similarly significant effects ("profiling")
                </p>
              </li>
            </ul>
            <p>
              Ennemoser Lukas has not sold any personal data to third parties
              for business or commercial purposes. Ennemoser Lukas will not sell
              personal data in the future belonging to website visitors, users,
              and other consumers.
            </p>
            <p>
              <u>Exercise your rights provided under the Virginia CDPA</u>
            </p>
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </p>
            <p>
              You may contact us by email at mouserstudios@gmail.com, by
              submitting a data subject access request, or by referring to the
              contact details at the bottom of this document.
            </p>
            <p>
              If you are using an authorized agent to exercise your rights, we
              may deny a request if the authorized agent does not submit proof
              that they have been validly authorized to act on your behalf.
            </p>
            <p>
              <u>Verification process</u>
            </p>
            <p>
              We may request that you provide additional information reasonably
              necessary to verify you and your consumer's request. If you submit
              the request through an authorized agent, we may need to collect
              additional information to verify your identity before processing
              your request.
            </p>
            <p>
              Upon receiving your request, we will respond without undue delay,
              but in all cases, within forty-five (45) days of receipt. The
              response period may be extended once by forty-five (45) additional
              days when reasonably necessary. We will inform you of any such
              extension within the initial 45-day response period, together with
              the reason for the extension.
            </p>
            <p>
              <u>Right to appeal</u>
            </p>
            <p>
              If we decline to take action regarding your request, we will
              inform you of our decision and reasoning behind it. If you wish to
              appeal our decision, please email us at mouserstudios@gmail.com.
              Within sixty (60) days of receipt of an appeal, we will inform you
              in writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions. If your appeal if denied, you may contact the Attorney
              General to submit a complaint.
            </p>
            <h1>13. DO WE MAKE UPDATES TO THIS NOTICE?</h1>
            <p>
              <strong>In Short: </strong>Yes, we will update this notice as
              necessary to stay compliant with relevant laws.
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
            <h1>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h1>
            <p>
              If you have questions or comments about this notice, you may email
              us at mouserstudios@gmail.com or by post to:
            </p>
            <p>Ennemoser Lukas</p>
            <p>Am Sand 25</p>
            <p>Moos, Bozen 39013</p>
            <p>Italy</p>
            <h1>
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h1>
            <p>
              You have the right to request access to the personal information
              we collect from you, change that information, or delete it. To
              request to review, update, or delete your personal information,
              please fill out and submit a data subject access request.
            </p>
            <p>
              This privacy policy was created using Termly's Privacy Policy
              Generator.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
