import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonRemoveAlt1OutlinedIcon from "@mui/icons-material/PersonRemoveAlt1Outlined";
import Button from "@mui/material/Button";
import { BorderClear, BorderColor } from "@mui/icons-material";
import { createNewPaymentSession } from "../firebase.js";
import { loadStripe } from "@stripe/stripe-js";

function createData(key, amount, expiry_date, users, expiry_date_ms) {
  return {
    key,
    amount,
    expiry_date,
    users,
    expiry_date_ms,
  };
}

const stripePromise = loadStripe(
  "pk_live_51LdFSnGS1y93BvSy0By9ovRecntSJWIgLcAJiFLKQshoL3JpstN47Q0MltKUFDS4Ja2gdiGovq6Yc6MTMKDCmNyq00rv2sNlxk"
);

async function renew(licence, amount, expiry_date) {
  // When the customer clicks on the button, redirect them to Checkout.

  var date = new Date(expiry_date);

  if (date < Date.now()) {
    var now = new Date(Date.now());
    now = now.setFullYear(now.getFullYear() + 1);
    date = new Date(now);
  } else {
    date.setFullYear(date.getFullYear() + 1);
  }

  const paymentId = await createNewPaymentSession(
    amount,
    licence,
    date.getTime()
  );

  var item =
    amount == 5
      ? "price_1NAZiKGS1y93BvSyAzG0oDnS"
      : "price_1NAZkzGS1y93BvSychYFU8Pu";

  const stripe = await stripePromise;
  const { error } = await stripe.redirectToCheckout({
    lineItems: [
      {
        price: item, // Replace with the ID of your price
        quantity: 1,
      },
    ],
    mode: "payment",
    successUrl:
      "https://biathlon.mouserstudios.com/pay/success?session_id=" + paymentId,
    cancelUrl: "https://biathlon.mouserstudios.com/profile",
  });
}

function removeUser(remove, key, user, setRows, row, rows) {
  //remove user from licence

  var r = [];

  for (let i = 0; i < rows.length; i++) {
    if (rows[i].key == key) {
      var count = 0;
      for (let j = 0; j < rows[i].users.length; j++) {
        if (rows[i].users[j].date == user.date) {
          break;
        }
        count++;
      }

      var ro = row;
      ro.users.splice(count, 1);
      r.push(ro);
    } else {
      r.push(rows[i]);
    }
  }

  setRows(r);

  remove(key, user.date);
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const maxUsers = row.amount.split("/")[1];

  if (maxUsers == 5 || maxUsers == 10) {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.key}
          </TableCell>
          <TableCell align="right">{row.expiry_date}</TableCell>
          <TableCell align="right">{row.amount}</TableCell>
          <TableCell align="right">
            <Button
              onClick={() => {
                renew(row.key, maxUsers, row.expiry_date_ms);
              }}
              variant="outlined"
              size="small"
              style={{
                borderColor: "#1e315b",
                color: "#1e315b",
              }}
            >
              Renew
            </Button>
            <a> </a>
            <Button
              onClick={() => {
                var index = row.amount.indexOf("/") + 1;

                props.onUpgrade(row.key, row.amount.substring(index));
              }}
              variant="outlined"
              size="small"
              style={{
                borderColor: "#1e315b",
                color: "#1e315b",
              }}
            >
              upgrade
            </Button>
          </TableCell>

          {/* <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2 }}>
                <span style={{ fontWeight: "bold" }}>Active users</span>
                <Table sx={{ maxWidth: 300 }} aria-label="purchases">
                  <TableBody>
                    {row.users.map((u) => (
                      <TableRow key={u.date}>
                        <TableCell component="th" scope="row">
                          {u.name}
                        </TableCell>

                        <TableCell component="th" scope="row" align="right">
                          <button
                            onClick={() => {
                              removeUser(
                                props.remove,
                                row.key,
                                u,
                                props.setRows,
                                props.row,
                                props.rows
                              );
                            }}
                          >
                            <PersonRemoveAlt1OutlinedIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else if (maxUsers == 1) {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.key}
          </TableCell>
          <TableCell align="right">{row.expiry_date}</TableCell>
          <TableCell align="right">{row.amount}</TableCell>
          <TableCell align="right">
            <Button
              onClick={() => {
                var index = row.amount.indexOf("/") + 1;

                props.onUpgrade(row.key, row.amount.substring(index));
              }}
              variant="outlined"
              size="small"
              style={{
                borderColor: "#1e315b",
                color: "#1e315b",
              }}
            >
              upgrade
            </Button>
            <a> </a>
          </TableCell>

          {/* <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2 }}>
                <span style={{ fontWeight: "bold" }}>Active users</span>
                <Table sx={{ maxWidth: 300 }} aria-label="purchases">
                  <TableBody>
                    {row.users.map((u) => (
                      <TableRow key={u.date}>
                        <TableCell component="th" scope="row">
                          {u.name}
                        </TableCell>

                        <TableCell component="th" scope="row" align="right">
                          <button
                            onClick={() => {
                              removeUser(
                                props.remove,
                                row.key,
                                u,
                                props.setRows,
                                props.row,
                                props.rows
                              );
                            }}
                          >
                            <PersonRemoveAlt1OutlinedIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.key}
          </TableCell>
          <TableCell align="right">{row.expiry_date}</TableCell>
          <TableCell align="right">{row.amount}</TableCell>
          <TableCell align="right">
            <Button
              onClick={() => {
                props.onCustomOffer(row.key);
              }}
              variant="outlined"
              size="small"
              style={{
                borderColor: "#1e315b",
                color: "#1e315b",
              }}
            >
              Custom Request
            </Button>
            <a> </a>
          </TableCell>

          {/* <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2 }}>
                <span style={{ fontWeight: "bold" }}>Active users</span>
                <Table sx={{ maxWidth: 300 }} aria-label="purchases">
                  <TableBody>
                    {row.users.map((u) => (
                      <TableRow key={u.date}>
                        <TableCell component="th" scope="row">
                          {u.name}
                        </TableCell>

                        <TableCell component="th" scope="row" align="right">
                          <button
                            onClick={() => {
                              removeUser(
                                props.remove,
                                row.key,
                                u,
                                props.setRows,
                                props.row,
                                props.rows
                              );
                            }}
                          >
                            <PersonRemoveAlt1OutlinedIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       })
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

export default function CollapsibleTable({
  data,
  removeUser,
  onUpgrade,
  onCustomOffer,
}) {
  var [rows, setRows] = useState([]);

  useEffect(() => {
    var r = [];
    for (let i = 0; i < data.length; i++) {
      r.push(
        createData(
          data[i].key,
          data[i].userAmount,
          data[i].expiry,
          data[i].users,
          data[i].expiry_date_ms
        )
      );
    }

    setRows(r);
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" sx={{ minWidth: 800 }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <span style={{ fontWeight: "bold" }}>Licence key</span>
            </TableCell>
            <TableCell align="right">
              <span style={{ fontWeight: "bold" }}>Expiry date</span>
            </TableCell>
            <TableCell align="right">
              <span style={{ fontWeight: "bold" }}>Users</span>
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row
              key={row.key}
              row={row}
              rows={rows}
              remove={removeUser}
              setRows={setRows}
              onUpgrade={onUpgrade}
              onCustomOffer={onCustomOffer}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
