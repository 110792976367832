import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { auth } from "../../firebase.js";

export default function FormDialog({
  isOpen = false,
  license = "",
  closeUpgrade,
}) {
  console.log("license: " + license);
  var text = "";

  const handleClickOpen = () => {
    closeUpgrade();
  };

  const handleClose = () => {
    closeUpgrade();
  };

  const sendMail = () => {
    const email = auth.currentUser.email;

    const Http = new XMLHttpRequest();

    const url =
      "https://europe-west3-mouserstudios-website.cloudfunctions.net/sendMail?name=" +
      "licensekey= " +
      license +
      "&email=" +
      email +
      "&message=" +
      text +
      "&subject=Biathlon Website Custom licence change request";
    Http.open("GET", url);
    Http.send();
    Http.onreadystatechange = (e) => {
      if (Http.readyState == 4) {
        if (Http.status == 200) {
          alert("Message sent! We will contact you soon.");
        } else {
          alert("Error sending message");
        }
      }
    };
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Custom licence</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You want to renew or upgrade your custom licence key "{license}"?
            Please enter your change request below. We will contact you.
          </DialogContentText>
          <TextField
            onChange={(e) => {
              text = e.target.value;
            }}
            style={{
              color: "#1e315b",
              borderColor: "#1e315b",
            }}
            autoFocus
            margin="dense"
            id="change"
            label="Change request"
            fullWidth
            multiline={true}
            minRows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              color: "#1e315b",
              borderColor: "#1e315b",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              sendMail();
              handleClose();
            }}
            style={{
              color: "#1e315b",
              borderColor: "#1e315b",
            }}
          >
            Send request
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
