import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { checkPaymentSession } from "../firebase.js";

export default function PaymentSuccess() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const check = async () => {
    await checkPaymentSession(searchParams.get("session_id"));
    navigate("/profile");
  };

  useEffect(() => {
    check();
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <ClipLoader color={"#274178"} loading={true} />
    </div>
  );
}
