import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import illustration from "images/login-illustration.svg";

import logo from "images/logo neu.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import {
  logInWithEmailAndPassword,
  sendForgotPasswordEmail,
} from "firebase.js";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default function Login() {
  const navigation = useNavigate();

  return <LoginComp navigation={navigation}></LoginComp>;
}

class LoginComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }
  handleChangePassword(event) {
    this.setState({
      password: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;
    var err = await logInWithEmailAndPassword(email, password);
    if (!err) {
      this.setState({ email: "", password: "", error: null });
      this.props.navigation("/profile", { replace: true });
    }
  }

  navigateToRegisterPage = () => {
    this.props.navigation("/register");
  };

  sendForgotPasswordEmail = () => {
    sendForgotPasswordEmail(this.state.email);
  };

  render() {
    return (
      <AnimationRevealPage disabled>
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href={"/"}>
                <LogoImage src={logo} />
              </LogoLink>
              <MainContent>
                <Heading>{"Sign In To RaceRecap"}</Heading>
                <FormContainer>
                  {/* <SocialButtonsContainer>
                  <button>
                    <SocialButton key={1}>
                      <span className="iconContainer">
                        <img src={googleIconImageSrc} className="icon" alt="" />
                      </span>
                    </SocialButton>
                  </button>
                </SocialButtonsContainer> */}
                  {/* <DividerTextContainer>
                  <DividerText>Or Sign in with your e-mail</DividerText>
                </DividerTextContainer> */}

                  <Form onSubmit={this.handleSubmit}>
                    <Input
                      type="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleChangeEmail}
                    />
                    <Input
                      type="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                    />

                    <SubmitButton type="submit">
                      <LoginIcon className="icon" />
                      <span className="text">{"Sign In"}</span>
                    </SubmitButton>
                  </Form>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    <button
                      tw="border-b border-gray-500 border-dotted"
                      onClick={this.sendForgotPasswordEmail}
                    >
                      Forgot Password ?
                    </button>
                  </p>
                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    Dont have an account?{" "}
                    <a tw="border-b border-gray-500 border-dotted">
                      <button onClick={this.navigateToRegisterPage}>
                        Sign Up
                      </button>
                    </a>
                  </p>
                </FormContainer>
              </MainContent>
            </MainContainer>
            {/* <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer> */}
          </Content>
        </Container>
      </AnimationRevealPage>
    );
  }
}
