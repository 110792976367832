import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`,
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

export default ({
  subheading = "",
  heading = "Questions",
  description = "Here are some little tutorials to help you. These tutorials are also available on YouTube under https://www.youtube.com/playlist?list=PLHDS78K6R88MQy6jPjIVm7TESNDVDLjsb . Should you have any other questions, feel free to reach out via the contact form below.",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null,
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Login",
      answer:
        "Once you have received your license code, open the app and enter your name in the first field. On the second page, enter your license code to log in. Your username is important for later removing devices that are no longer in use in the dashboard.",
    },
    {
      question: "Add Athletes",
      answer:
        'To create a new athlete, switch to the "Athletes" tab. Click on the plus button and enter the necessary information such as name, surname, gender, and country of origin. Additionally, you can also upload a photo of the athlete here. Finally, click on "Save" to store the information.',
    },
    {
      question: "Add Competitions",
      answer:
        'To create a new competition, click on the plus button on the homepage. Enter all necessary information such as a name, location, date, and type of competition. The selected type of competition affects the number and order of the shootings. If you plan to enter the shooting results, add all your athletes who will compete in the competition. Clicking on the plus button opens a window where all previously created athletes are listed. Once one or more athletes have been selected, the "Add" button will become visible. Clicking on this saves the information and closes the athlete window. Finally, save the competition.',
    },
    {
      question: "Settings",
      answer:
        "In the settings, which can be accessed from the top right of the homepage, it is possible to adjust the size of the shots to optimize the user experience on any device. Additionally, you can log out here if you want to sign in with a different license code.",
    },
    {
      question: "Athlete Performance",
      answer:
        'If you are interested in viewing the statistics of an athlete from the current or previous seasons, switch to the "Athletes" tab and select the desired athlete. All recorded shooting results are visible on this page, as well as the calculated hit rate in the prone and standing positions. In the combo box above the hit rate, it is possible to select one of the available seasons and display the corresponding data.',
    },
    {
      question: "Add Shootings",
      answer:
        'At the selected competition, you open the "Shooting" tab and select the athlete who is at the shooting range. An option window will appear offering you all the available shootings depending on the type of competition. You choose the desired shooting and enter the hit pattern by pressing the target. If it is necessary to modify a shot, you simply select it at the bottom and click on the target again to correct it. If you need to delete a shot, hold it down for a longer period. In case of unstable wind conditions, simply add the wind direction and strength by clicking on the wind vane at the top right. In the open window, slide the lever to the left or right to adjust the vane. Finally, you save the shooting pattern. By saving, all team members will be notified and can communicate the shooting pattern to the athlete on the track. This way, the team can work together seamlessly and successfully compete.',
    },
    {
      question: "Add Startlist",
      answer:
        'To stay informed of all intermediate times during a competition, a start list must be created. This can be entered under the "Ski Times" tab in the competition. To do this, click on the menu button and select the "Edit Startlist" button. On the page that appears, enter the start number, name, category, and start time. To select the category, a window will appear with all available categories. If the desired category is not listed, a new one can be created here. Save this participant afterwards. The start number and start times will be automatically calculated and pre-filled after the entry of the second participant. To edit a participant, select them from the list and change the data, then save them. If there are too many athletes, there is also the option to import a CSV file. To do this, click on the "File" icon in the upper right corner. Select the file and enter all information about the CSV file, this means, entering the column number of the listed parameters. If a parameter is not present in the file, it can be deselected using the checkbox. If there are no start times in the imported file, the start interval will be queried when clicking "Import". If the start list has been entered correctly, save it in the upper right corner.',
    },
    {
      question: "Stop Times",
      answer:
        "To record split times during a competition, simply open the 'Ski Times' tab and click on the menu button. Then select the 'Stop Time' option. Please note that these times are always based on the start times in the start list. If the race starts earlier or later than expected and you have no way to update the start list, don't worry! You can manually start the race by holding down the start button for a long time when the first athlete starts. Once the race has started and an athlete passes the timekeeper, simply enter their start number to record their time. Then confirm your entry in the bottom right corner of the screen. If you happen to enter the wrong start number, simply delete the entry by holding down the line. To view the start list and all intermediate times, simply expand the intermediate times at the top right of the screen. Additionally, you can edit or delete any intermediate time by simply tapping on it. Once you have saved your changes, they will take effect immediately. The stop function can be used by several timekeepers at the same time.",
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="scroll-tutorial">
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image
              imageContain={imageContain}
              imageShadow={imageShadow}
              imageSrc={imageSrc}
            />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>
                <a>
                  Here are some little tutorials to help you. These tutorials
                  are also available on YouTube under this{" "}
                  <a
                    style={{
                      color: "#1e315b",
                    }}
                    href="https://www.youtube.com/playlist?list=PLHDS78K6R88MQy6jPjIVm7TESNDVDLjsb"
                    target="_blank"
                  >
                    link
                  </a>
                  . Should you have any other questions, feel free to reach out
                  via the contact form below.
                </a>
              </Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? (
                          <MinusIcon />
                        ) : (
                          <PlusIcon />
                        )}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                      }}
                      initial="collapsed"
                      animate={
                        activeQuestionIndex === index ? "open" : "collapsed"
                      }
                      transition={{
                        duration: 0.3,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
