// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signOut,
  browserSessionPersistence,
  setPersistence,
  inMemoryPersistence,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  collection,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await setPersistence(auth, browserSessionPersistence);
    await signInWithEmailAndPassword(auth, email, password).then((user) => {
      return false;
    });
  } catch (err) {
    alert(err.message);
    return true;
  }
};

const logout = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    alert(err.message);
  }
};

const cUserWithEmailAndPassword = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password).then((user) => {
      return false;
    });
  } catch (err) {
    alert(err.message);
    return true;
  }
};

const sendForgotPasswordEmail = async (email) => {
  var error = false;
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    error = true;
    alert(err.message);
  }

  if (!error) {
    alert("Password reset email sent to your entered email address");
  }
};

const readLicenceKeysFromUser = async () => {
  var user = auth.currentUser;

  const db = getFirestore(app);
  const docRef = doc(db, "users", user.uid);

  var data = await getDoc(docRef);

  if (!data.exists()) {
    data = await setDoc(doc(db, "users", user.uid), {
      email: user.email,
      licenses: [],
      trial_used: false,
    });
  }

  try {
    if (data.data().licenses.length == 0) {
      return null;
    } else {
      var licenses = [];
      for (let i = 0; i < data.data().licenses.length; i++) {
        const docR = doc(db, "licenses", data.data().licenses[i]);
        var d = await getDoc(docR);
        var u = [];

        for (let j = 0; j < d.data().devices.length; j++) {
          if (d.data().devices[j].logged_in == true) {
            u.push({
              name: d.data().devices[j].name,
              date: d.data().devices[j].date,
            });
          }
        }

        var o = {
          key: data.data().licenses[i],
          amount: d.data().amount,
          expiry_date: d.data().expiry_date,
          users: u,
        };
        if (d.exists()) {
          licenses.push(o);
        }
      }
      licenses.push(data.data().trial_used);
      return licenses;
    }
  } catch (err) {
    return [];
  }
};

const createTrialLicense = async () => {
  const db = getFirestore(app);

  const currentTime = new Date(2099, 0, 1);
  // currentTime.setDate(currentTime.getDate() + 5);
  const milliseconds = currentTime.getTime();

  var data = await addDoc(collection(db, "licenses"), {
    amount: 1,
    expiry_date: milliseconds,
    devices: [],
    request_date: new Date().getTime(),
  });

  var user = auth.currentUser;

  const docRef2 = doc(db, "users", user.uid);

  var data2 = await getDoc(docRef2);

  if (data2.exists()) {
    var l = [];
    l = data2.data().licenses;
    l.push(data.id);

    data2 = await setDoc(doc(db, "users", user.uid), {
      email: user.email,
      licenses: l,
      trial_used: true,
    });
  }
};

const createStandardLicense = async () => {
  const db = getFirestore(app);

  const currentTime = new Date();
  currentTime.setDate(currentTime.getDate() + 366);
  const milliseconds = currentTime.getTime();

  var data = await addDoc(collection(db, "licenses"), {
    amount: 5,
    expiry_date: milliseconds,
    devices: [],
    request_date: new Date().getTime(),
  });

  var user = auth.currentUser;

  const docRef2 = doc(db, "users", user.uid);

  var data2 = await getDoc(docRef2);

  if (data2.exists()) {
    var l = [];
    l = data2.data().licenses;
    l.push(data.id);

    data2 = await setDoc(doc(db, "users", user.uid), {
      email: user.email,
      licenses: l,
      trial_used: true,
    });
  }
};

const createPremiumLicense = async () => {
  const db = getFirestore(app);

  const currentTime = new Date();
  currentTime.setDate(currentTime.getDate() + 366);
  const milliseconds = currentTime.getTime();

  var data = await addDoc(collection(db, "licenses"), {
    amount: 10,
    expiry_date: milliseconds,
    devices: [],
    request_date: new Date().getTime(),
  });

  var user = auth.currentUser;

  const docRef2 = doc(db, "users", user.uid);

  var data2 = await getDoc(docRef2);

  if (data2.exists()) {
    var l = [];
    l = data2.data().licenses;
    l.push(data.id);

    data2 = await setDoc(doc(db, "users", user.uid), {
      email: user.email,
      licenses: l,
      trial_used: true,
    });
  }
};

const cickAllUsers = async (key) => {
  const db = getFirestore(app);
  await updateDoc(doc(db, "licenses", key), {
    devices: [],
  });
};

const cickOneUser = async (key, date) => {
  const db = getFirestore(app);
  const docRef = doc(db, "licenses", key);
  var data = await getDoc(docRef);

  var devices = data.data().devices;

  for (let i = 0; i < devices.length; i++) {
    if (devices[i].date == date) {
      devices[i].logged_in = false;
    }
  }

  await updateDoc(doc(db, "licenses", key), {
    devices: devices,
  });
};

const createNewPaymentSession = async (amount, licence, endDate) => {
  const db = getFirestore(app);

  var data = await addDoc(collection(db, "payment_session"), {
    hasProcessed: false,
    endDate: endDate,
    amount: amount,
    licence: licence,
  });

  return data.id;
};

const checkPaymentSession = async (id) => {
  const db = getFirestore(app);

  const docRef = doc(db, "payment_session", id);

  var data = await getDoc(docRef);

  if (data.exists()) {
    if (data.data().licence == null) {
      if (data.data().amount == 5) {
        await createStandardLicense();
      } else if (data.data().amount == 10) {
        await createPremiumLicense();
      }
    } else {
      await updateDoc(doc(db, "licenses", data.data().licence), {
        expiry_date: data.data().endDate,
        amount: data.data().amount,
      });
    }

    await updateDoc(doc(db, "payment_session", id), {
      hasProcessed: true,
    });
  }
};

export {
  logInWithEmailAndPassword,
  sendForgotPasswordEmail,
  cUserWithEmailAndPassword,
  readLicenceKeysFromUser,
  cickAllUsers,
  cickOneUser,
  logout,
  createTrialLicense,
  createStandardLicense,
  createPremiumLicense,
  createNewPaymentSession,
  checkPaymentSession,
};

export const auth = getAuth(app);

export default app;
