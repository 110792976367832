import React from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import { createNewPaymentSession } from "../firebase.js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51LdFSnGS1y93BvSy0By9ovRecntSJWIgLcAJiFLKQshoL3JpstN47Q0MltKUFDS4Ja2gdiGovq6Yc6MTMKDCmNyq00rv2sNlxk"
);

function Test() {
  const handleClick = async (event) => {
    const paymentId = await createNewPaymentSession(5, null, 181265);

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1NAZEPGS1y93BvSyWV0ukqlR", // Replace with the ID of your price
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl:
        "https://biathlon.mouserstudios.com/pay/success?session_id=" +
        paymentId,
      cancelUrl: "https://biathlon.mouserstudios.com/profile",
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };
  return (
    <button role="link" onClick={handleClick}>
      Checkout
    </button>
  );
}
// ReactDOM.render(<Test />, document.getElementById("root"));

export default Test;
