import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher";
import {
  readLicenceKeysFromUser,
  cickAllUsers,
  cickOneUser,
  createTrialLicense,
  createStandardLicense,
  createPremiumLicense,
  createNewPaymentSession,
  auth,
} from "../firebase.js";
import { loadStripe } from "@stripe/stripe-js";
import { ClipLoader } from "react-spinners";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RxExit } from "react-icons/rx";
import CollapsibleTable from "./Table.js";
import UpgradeDialog from "components/dialog/upgradedialog.js";
import CustomDialog from "components/dialog/customdialog.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;

const Description = tw.div``;

function createData(key, userAmount, expiry, users, expiry_date_ms) {
  const date = new Date(expiry);

  expiry = date.toLocaleDateString("de-DE");

  userAmount = users.length + "/" + userAmount;

  return { key, userAmount, expiry, users, expiry_date_ms };
}

function cickUser(key, date, setIsLoading, setRows) {
  cickOneUser(key, date).then(() => {});
}

const stripePromise = loadStripe(
  "pk_live_51LdFSnGS1y93BvSy0By9ovRecntSJWIgLcAJiFLKQshoL3JpstN47Q0MltKUFDS4Ja2gdiGovq6Yc6MTMKDCmNyq00rv2sNlxk"
);

const handleClick = async (userAmount, licence) => {
  var date = new Date();

  date = date.setFullYear(date.getFullYear() + 1);

  const paymentId = await createNewPaymentSession(
    userAmount,
    licence == null ? null : licence,
    date
  );

  // When the customer clicks on the button, redirect them to Checkout.

  var item =
    userAmount == 5
      ? "price_1NAZiKGS1y93BvSyAzG0oDnS"
      : "price_1NAZkzGS1y93BvSychYFU8Pu";

  const stripe = await stripePromise;
  const { error } = await stripe.redirectToCheckout({
    lineItems: [
      {
        price: item, // Replace with the ID of your price
        quantity: 1,
      },
    ],
    mode: "payment",
    successUrl:
      "https://biathlon.mouserstudios.com/pay/success?session_id=" + paymentId,
    cancelUrl: "https://biathlon.mouserstudios.com/profile",
  });
  // If `redirectToCheckout` fails due to a browser or network
  // error, display the localized error message to your customer
  // using `error.message`.
};

function sendMessageOnTrialCreated() {
  const email = auth.currentUser.email;

  const Http = new XMLHttpRequest();

  const url =
    "https://europe-west3-mouserstudios-website.cloudfunctions.net/sendMail?name=" +
    "  " +
    "&email=" +
    email +
    "&message=" +
    "Free Version created" +
    "&subject=Biathlon Website Free version created";
  Http.open("GET", url);
  Http.send();
}

export default ({}) => {
  var [trialUsed, setTrialUsed] = useState(false);
  var [userData, setUserData] = useState(null);

  var [isLoading, setIsLoading] = useState(true);

  var [rows, setRows] = useState([]);

  var [upgradeOpen, setupgradeOpen] = useState(false);
  var [licenceKey, setLicenceKey] = useState("");
  var [userAmount, setUserAmount] = useState(0);

  var [customOfferOpen, setcustomOfferOpen] = useState(false);

  function onUpgrade(key, amount) {
    setLicenceKey(key);
    setUserAmount(amount);
    setupgradeOpen(true);
  }

  function closeUpgrade() {
    setLicenceKey("");
    setupgradeOpen(false);
  }

  function onCustomOffer(key) {
    setLicenceKey(key);
    setcustomOfferOpen(true);
  }

  function closeCustomOffer() {
    setLicenceKey("");
    setcustomOfferOpen(false);
  }

  function createTrial() {
    setIsLoading(true);

    createTrialLicense().then(() => {
      sendMessageOnTrialCreated();
      readLicenceKeysFromUser().then((data) => {
        var r = [];
        if (data == null) {
          setIsLoading(false);
        }

        for (let i = 0; i < data.length - 1; i++) {
          r.push(
            createData(
              data[i].key,
              data[i].amount,
              data[i].expiry_date,
              data[i].users,
              data[i].expiry_date
            )
          );
        }

        setRows(r);

        var trialUsed = data[data.length - 1];

        setTrialUsed(trialUsed);
        setUserData(data);
        setIsLoading(false);
      });
    });
  }

  function buyStandard(licence) {
    handleClick(5, licence);
  }

  function buyPremium(licence) {
    handleClick(10, licence);
  }

  useEffect(() => {
    readLicenceKeysFromUser().then((data) => {
      var r = [];
      if (data == null) {
        setIsLoading(false);
      }

      for (let i = 0; i < data.length - 1; i++) {
        r.push(
          createData(
            data[i].key,
            data[i].amount,
            data[i].expiry_date,
            data[i].users,
            data[i].expiry_date
          )
        );
      }

      setRows(r);

      var trialUsed = data[data.length - 1];

      setTrialUsed(trialUsed);
      setUserData(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ClipLoader color={"#274178"} loading={true} />
      </div>
    );
  }

  if (userData == null) {
    return (
      <AnimationRevealPage disabled>
        <Header />

        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{"Licences"}</Heading>
            </HeadingRow>

            <a
              style={{
                display: "flex",
                height: "40px",
                paddingTop: "20px",
              }}
            >
              You don't have a licence key yet! Start now with your trial plan.
            </a>
          </ContentWithPaddingXl>
        </Container>
        <Pricing
          trial={!trialUsed}
          dashboard={true}
          createTrial={createTrial}
          createStandard={buyStandard}
          createPremium={buyPremium}
        />
        <Footer />
      </AnimationRevealPage>
    );
  } else {
    return (
      <AnimationRevealPage disabled>
        <Header />
        <UpgradeDialog
          isOpen={upgradeOpen}
          license={licenceKey}
          closeUpgrade={closeUpgrade}
          amount={userAmount}
          onPremium={buyPremium}
          onStandard={buyStandard}
        ></UpgradeDialog>
        <CustomDialog
          isOpen={customOfferOpen}
          license={licenceKey}
          closeUpgrade={closeCustomOffer}
        ></CustomDialog>
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{"Licences"}</Heading>
            </HeadingRow>
          </ContentWithPaddingXl>
          <CollapsibleTable
            data={rows}
            removeUser={cickUser}
            onUpgrade={onUpgrade}
            onCustomOffer={onCustomOffer}
          ></CollapsibleTable>
        </Container>
        <Pricing
          trial={true}
          dashboard={true}
          createTrial={createTrial}
          createStandard={buyStandard}
          createPremium={buyPremium}
        />
        <Footer />
      </AnimationRevealPage>
    );
  }
};
